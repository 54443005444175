document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".edit-profile")) {
    new Inputmask("99999-999").mask(document.getElementById("profile_cep"));
    new Inputmask("99999999999").mask(document.getElementById("profile_license_number"));
    new Inputmask("(99) 99999-9999").mask(document.getElementById("profile_phone_number"));

    document.getElementById("profile_cep").addEventListener("input", async (e) => {
      const cep = e.currentTarget.value.replace(/\D+/g, '');
      if (cep.length === 8) {
        const url =`https://viacep.com.br/ws/${cep}/json/`

        const response = await axios.get(url)
        if (response.status === 200 && !response.data.erro) {
          document.getElementById("profile_street_name").value = response.data.logradouro
					if (!response.data.logradouro) document.getElementById("profile_street_name").removeAttribute('readonly');
          document.getElementById("profile_neighborhood").value = response.data.bairro
					if (!response.data.bairro) document.getElementById("profile_neighborhood").removeAttribute('readonly');
          document.getElementById("profile_city").value = response.data.localidade
          document.getElementById("profile_state").value = response.data.uf
        } else {
          document.getElementById("profile_street_name").value = "CEP Inválido"
          document.getElementById("profile_neighborhood").value = "CEP Inválido"
          document.getElementById("profile_city").value = "CEP Inválido"
          document.getElementById("profile_state").value = "CEP Inválido"
        }
      } else {
        document.getElementById("profile_street_name").value = ""
        document.getElementById("profile_neighborhood").value = ""
        document.getElementById("profile_city").value = ""
        document.getElementById("profile_state").value = ""
				document.getElementById("profile_street_name").setAttribute( 'readonly', true )
				document.getElementById("profile_neighborhood").setAttribute( 'readonly', true )
      }
    })
  }
})
