document.addEventListener("DOMContentLoaded", async () => {
  if (document.querySelector(".car-form")) {
		const manufacturerOptionsEl = document.getElementById("manufacturer-options")
		const modelOptionsEl = document.getElementById("model-options")

    new Inputmask("***-****").mask(document.getElementById("car_plate"));
    new Inputmask("99999999999").mask(document.getElementById("car_renavam"));
    new Inputmask("9999/9999").mask(document.getElementById("car_year"));

		const manualCarInputCheckbox = document.querySelector(".select-car-manually-checkbox");
		manualCarInputCheckbox.addEventListener("change", (e) => {
			toggleManualCar(e.target.checked)
		})

		const toggleManualCar = (show) => {
			if (show) {
				document.querySelector(".select-car-inputs").classList.remove("hide")
				document.querySelector(".select-car-options").classList.add("hide")
			} else {
				document.querySelector(".select-car-inputs").classList.add("hide")
				document.querySelector(".select-car-options").classList.remove("hide")
			}
		}
		manufacturerOptionsEl.addEventListener("change", (e) => {
			const manufacturerName = e.currentTarget.value;
			fetchModels(manufacturerName)
			document.getElementById("car_manufacturer").value = manufacturerName;
			document.getElementById("car_model").value = "";
		})
		modelOptionsEl.addEventListener("change", (e) => {
			const modelName = e.currentTarget.value;
			document.getElementById("car_model").value = modelName;
		})

		const fetchModels = async (manufacturerName, selectModel = "") => {
			const url = `/client/fipe/${manufacturerName}.json`

			try {
				const response = await axios.get(url);

				models = response.data["models"];

				modelOptionsEl.innerHTML = "";

				modelOptionsEl.insertAdjacentHTML("beforeend", `<option disabled selected value>Selecione uma opção</option>`)

				models.forEach((model) => {
					modelOptionsEl.insertAdjacentHTML("beforeend", `<option value="${model.name}">${model.name}</option>"`)
				})
				if (selectModel) modelOptionsEl.value = selectModel;
			} catch (err) {
				manufacturerOptionsEl.value = "";
				modelOptionsEl.value = ""
				toggleManualCar(true);
				manualCarInputCheckbox.checked = true;
			}
			if (selectModel) document.querySelector("#car_model").value = selectModel;
		}

		if (!Array.from(manufacturerOptionsEl.options).map(o => o.value).includes(document.getElementById("car_manufacturer").value)) {
			toggleManualCar(true);
			manualCarInputCheckbox.checked = true;
		}
		if (!Array.from(modelOptionsEl.options).map(o => o.value).includes(document.getElementById("car_model").value)) {
			toggleManualCar(true);
			manualCarInputCheckbox.checked = true;
		}
  }
})
