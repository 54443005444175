document.addEventListener("DOMContentLoaded", async () => {
	onboardingFinish = document.querySelector("#concludeOnboarding");
	onboardingContainer = document.querySelector("#onboardingContainer");

	if (!onboardingContainer) return;

	onboardingFinish.addEventListener("click", () => {
		onboardingContainer.remove();
	})
})
