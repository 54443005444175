document.addEventListener("DOMContentLoaded", async () => {
	new Inputmask("999.999.999-99").mask(document.getElementById("user_cpf"));
	
  if (document.querySelector(".user-form")) {
		
		submitBtn = document.querySelector("#submit-btn")
		acceptBtn = document.querySelector("#accept-btn")
		termsContent = document.querySelector(".terms-content")
		modalBody = document.querySelector(".modal-body")
		scrollToAcceptMessage = document.querySelector("#scroll-to-accept-message")

		const registrationToggleButton = document.querySelector("#registration-toggle-btn");
		const registrationForm = document.querySelector("#registration-form");
		const eventIdentifier = document.querySelector("#event-identifier") && document.querySelector("#event-identifier").innerHTML
		const invitedEmail = document.querySelector("#invited-email") && document.querySelector("#invited-email").innerHTML

		const showRegistrationForm = async () => {
			registrationForm.classList.remove("hide");
			if (!eventIdentifier) return;
			if (![...registrationForm.classList].includes("hide")) scrollToElement(registrationForm, 800);
			await axios.patch(`/client/events/${eventIdentifier}/track`, { trackeable: "track_open_registration_form" });
		}

		if (!eventIdentifier) {
			showRegistrationForm();
		}

		registrationToggleButton.addEventListener("click", showRegistrationForm)

		const errorOnForm = document.querySelector(".invalid-feedback")
		if (errorOnForm) {
			registrationForm.classList.remove("hide");
			scrollToElement(errorOnForm, 800, -80);
		}

		modalBody.addEventListener("scroll", () => {
			if (modalBody.scrollTop + modalBody.offsetHeight > 0.8 * modalBody.scrollHeight )  {
				acceptBtn.disabled = false;
				scrollToAcceptMessage.classList.add("hide");
			}
		});

		acceptBtn.addEventListener("click", () => {
			if (!acceptBtn.disabled) {
				submitBtn.disabled = false
			}
		})

		if (document.getElementById("user_cep")) {
			new Inputmask("99999-999").mask(document.getElementById("user_cep"));

			document.getElementById("user_cep").addEventListener("input", async (e) => {
				const cep = e.currentTarget.value.replace(/\D+/g, '');
				if (cep.length === 8) {
					const url =`https://viacep.com.br/ws/${cep}/json/`

					const response = await axios.get(url)
					if (response.status === 200 && !response.data.erro) {
						document.getElementById("user_street_name").value = response.data.logradouro
						if (!response.data.logradouro) document.getElementById("user_street_name").removeAttribute('readonly');
						document.getElementById("user_neighborhood").value = response.data.bairro
						if (!response.data.bairro) document.getElementById("user_neighborhood").removeAttribute('readonly');
						document.getElementById("user_city").value = response.data.localidade
						document.getElementById("user_state").value = response.data.uf
					} else {
						document.getElementById("user_street_name").value = "CEP Inválido"
						document.getElementById("user_neighborhood").value = "CEP Inválido"
						document.getElementById("user_city").value = "CEP Inválido"
						document.getElementById("user_state").value = "CEP Inválido"
					}
				} else {
					document.getElementById("user_street_name").value = ""
					document.getElementById("user_neighborhood").value = ""
					document.getElementById("user_city").value = ""
					document.getElementById("user_state").value = ""
					document.getElementById("user_street_name").setAttribute( 'readonly', true )
					document.getElementById("user_neighborhood").setAttribute( 'readonly', true )
				}
			})
		}
  } else {
		return;
	}
})
