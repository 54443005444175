document.addEventListener("DOMContentLoaded", async () => {
	if (document.querySelector(".edit-event")) {
		const userCars = JSON.parse(document.querySelector("#user_cars").innerHTML.toString());
		const selectPreviousCarEl = document.querySelector("#event_car_id");

		new Inputmask("999.999.999-99").mask(document.getElementById("event_cpf"));
    new Inputmask("99999999999").mask(document.getElementById("event_license_number"));
    new Inputmask("(99) 99999-9999").mask(document.getElementById("event_phone_number"));
		new Inputmask("99999-999").mask(document.getElementById("event_cep"));

		document.getElementById("event_cep").addEventListener("input", async (e) => {
			const cep = e.currentTarget.value.replace(/\D+/g, '');
			if (cep.length === 8) {
				const url =`https://viacep.com.br/ws/${cep}/json/`

				const response = await axios.get(url)
				if (response.status === 200 && !response.data.erro) {
					document.getElementById("event_street_name").value = response.data.logradouro
					if (!response.data.logradouro) document.getElementById("event_street_name").removeAttribute('readonly');
					document.getElementById("event_neighborhood").value = response.data.bairro
					if (!response.data.logradouro) document.getElementById("event_neighborhood").removeAttribute('readonly');
					document.getElementById("event_city").value = response.data.localidade
					document.getElementById("event_state").value = response.data.uf
				} else {
					document.getElementById("event_street_name").value = "CEP Inválido"
					document.getElementById("event_neighborhood").value = "CEP Inválido"
					document.getElementById("event_city").value = "CEP Inválido"
					document.getElementById("event_state").value = "CEP Inválido"
				}
			} else {
				document.getElementById("event_street_name").value = ""
				document.getElementById("event_neighborhood").value = ""
				document.getElementById("event_city").value = ""
				document.getElementById("event_state").value = ""
				document.getElementById("event_street_name").setAttribute( 'readonly', true )
				document.getElementById("event_neighborhood").setAttribute( 'readonly', true )
			}
		})

		const manufacturerOptionsEl = document.getElementById("manufacturer-options")
		const modelOptionsEl = document.getElementById("model-options")

		new Inputmask("***-****").mask(document.getElementById("event_plate"));
		new Inputmask("99999999999").mask(document.getElementById("event_renavam"));
		new Inputmask("9999/9999").mask(document.getElementById("event_year"));

		const manualCarInputCheckbox = document.querySelector(".select-car-manually-checkbox");
		manualCarInputCheckbox.addEventListener("change", (e) => {
			toggleManualCar(e.target.checked)
		})

		const toggleManualCar = (show) => {
			if (show) {
				document.querySelector(".select-car-inputs").classList.remove("hide")
				document.querySelector(".select-car-options").classList.add("hide")
			} else {
				document.querySelector(".select-car-inputs").classList.add("hide")
				document.querySelector(".select-car-options").classList.remove("hide")
			}
		}
		manufacturerOptionsEl.addEventListener("change", (e) => {
			const manufacturerName = e.currentTarget.value;
			if (selectPreviousCarEl) selectPreviousCarEl.value = "";
			fetchModels(manufacturerName)
			document.getElementById("event_manufacturer").value = manufacturerName;
			document.getElementById("event_model").value = "";
		})
		modelOptionsEl.addEventListener("change", (e) => {
			const modelName = e.currentTarget.value;
			if (selectPreviousCarEl) selectPreviousCarEl.value = "";
			document.getElementById("event_model").value = modelName;
		})

		const fetchModels = async (manufacturerName, selectModel = "") => {
			const url = `/client/fipe/${manufacturerName}.json`

			try {
				const response = await axios.get(url);

				models = response.data["models"];

				modelOptionsEl.innerHTML = "";

				modelOptionsEl.insertAdjacentHTML("beforeend", `<option disabled selected value>Selecione uma opção</option>`)

				models.forEach((model) => {
					modelOptionsEl.insertAdjacentHTML("beforeend", `<option value="${model.name}">${model.name}</option>"`)
				})
				if (selectModel) modelOptionsEl.value = selectModel;
			} catch (err) {
				manufacturerOptionsEl.value = "";
				modelOptionsEl.value = ""
				toggleManualCar(true);
				manualCarInputCheckbox.checked = true;
			}
			if (selectModel) document.querySelector("#event_model").value = selectModel;
		}

		const fillPreviousCarInformation = (selectedCarId) => {
			const selectedCar = userCars.find((car) => car.id.toString() == selectedCarId);
			document.querySelector("#event_manufacturer").value = selectedCar.manufacturer
			document.querySelector("#event_model").value = selectedCar.manufacturer
			document.querySelector("#event_year").value = selectedCar.year
			document.querySelector("#event_renavam").value = selectedCar.renavam
			document.querySelector("#event_plate").value = selectedCar.plate
			fetchModels(selectedCar.manufacturer, selectedCar.model)
			document.querySelector(".new-car").classList.add("hide")
		}

		const selectACarById = (carId) => {
			if (!!carId) {
				fillPreviousCarInformation(carId)
				document.querySelector(".select-new-car-helper").classList.remove("hide")
			} else {
				document.querySelector(".new-car").classList.remove("hide")
				document.querySelector(".select-new-car-helper").classList.add("hide")
				toggleManualCar(false);
				manualCarInputCheckbox.checked = false;
				document.querySelector("#event_manufacturer").value = ""
				document.querySelector("#event_model").value = ""
				document.querySelector("#event_year").value = ""
				document.querySelector("#event_renavam").value = ""
				document.querySelector("#event_plate").value = ""
			}
		}
		const initiallySelectedCarId = selectPreviousCarEl.value;
		selectACarById(initiallySelectedCarId)

		selectPreviousCarEl.addEventListener("change", (event) => {
			const selectedCarId = event.currentTarget.value;
			selectACarById(selectedCarId)
		})
	} else {
		return;
	}
})
