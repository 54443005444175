document.addEventListener("DOMContentLoaded", () => {
	paymentContainer = document.querySelector(".invoice-payment")
	
	if (!paymentContainer) return;

	const invoiceId = paymentContainer.dataset.invoiceid;
	let stopPolling = false;

	const poolFunction = async () => {
		if (stopPolling) return;
		const url = `/invoices/${invoiceId}/status.json`

		const response = await axios.get(url);

		console.log(response)
	
		if (response.data) {
			document.querySelector(".payment-confirmed").classList.remove("hide")
			document.querySelector(".invoice-payment").classList.add("hide")
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
			stopPolling = true;
		}
	}

	poolFunction();

	setInterval(poolFunction, 4000)
})
