document.addEventListener("DOMContentLoaded", () => {
	inviteForm = document.querySelector(".invite-form")
	
	if (!inviteForm) return;

	const inviteBtn = document.querySelector("#copy-link-button")

	inviteBtn.addEventListener("click", () => {
		if (document.selection) {
			var range = document.body.createTextRange();
			range.moveToElementText(document.getElementById("user-invite-message"));
			range.select().createTextRange();
			document.execCommand("copy");
		} else if (window.getSelection) {
			var range = document.createRange();
			range.selectNode(document.getElementById("user-invite-message"));
			window.getSelection().removeAllRanges(range);
			window.getSelection().addRange(range);
			document.execCommand("copy");
		}
		
		inviteBtn.innerHTML = `<i class="fas fa-clipboard-check mr-5 fs-48"></i>Mensagem copiada com sucesso. Convide usuários para ganhar créditos!`
		inviteBtn.classList.remove("btn-primary")
		inviteBtn.classList.add("btn-success")
	})
})
