document.addEventListener("DOMContentLoaded", async () => {
	const pageMobileDesktopVisibility = () => {
		deviceWidth = window.innerWidth;
		desktopPages = document.querySelectorAll('.page-desktop')
		mobilePages = document.querySelectorAll('.page-mobile')

		if (deviceWidth < 768) {
			desktopPages.forEach((page) => {
				console.log(page)
				page.style.display = 'none'
			})
			mobilePages.forEach((page) => {
				page.style.display = ''
			})
		}
		if (deviceWidth >= 768) {
			desktopPages.forEach((page) => {
				page.style.display = ''
			})
			mobilePages.forEach((page) => {
				page.style.display = 'none'
			})
		}
	}

	if (document.querySelectorAll('.page-desktop')) {
		// pageMobileDesktopVisibility();

		// On resize
		// window.addEventListener ('resize', pageMobileDesktopVisibility)
	}

  const selects = document.querySelectorAll("select").forEach((select) => {
    select.classList.add("select-css");
  })

  const alert = document.querySelector(".alert")

  if (alert) {
    alert.classList.add("show");

    setTimeout(() => {
      alert.classList.remove("show");
    }, 2900)
  }

  fileInputs = document.querySelectorAll('.inputfile')

  fileInputs.forEach((fi) => {
    label = fi.parentNode.querySelector("label")

    fi.addEventListener('change', function() {
      label.innerHTML = event.target.files[0].name
    })
  })
})
