document.addEventListener("DOMContentLoaded", async () => {
	if (!document.querySelector('#editor-container')) return;

	const imageHandler = () => {
		const editor = quill;
		const range = editor.getSelection();
		const value = prompt('URL da imagem para adicionar');
		editor.insertEmbed(range.index, 'image', value, Quill.sources.USER);
	}

	var quill = new Quill('#editor-container', {
		modules: {
			toolbar: [
					['bold', 'italic', 'underline', 'strike', 'link', 'image', 'blockquote'],
					[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
					[{ 'list': 'ordered'}, { 'list': 'bullet' }],
					[{ 'indent': '-1'}, { 'indent': '+1' }],
					[{ 'size': ['small', false, 'large', 'huge'] }],
					[{ 'color': [] }, { 'background': [] }],
					[{ 'font': [] }],
					[{ 'align': [] }],
				],
			},
			theme: "snow"
		});

	document.querySelector('.post-form').addEventListener("submit", () => {
		document.querySelector('#post_content').value = quill.root.innerHTML
	})
})
