document.addEventListener("DOMContentLoaded", async () => {
  if (document.querySelector(".partners-form")) {
    new Inputmask("99999-999").mask(document.getElementById("partner_cep"));
    new Inputmask("99.999.999/0001-99").mask(
      document.getElementById("partner_cnpj")
    );
    new Inputmask("999.999.999-99").mask(
      document.getElementById("partner_cpf")
    );
    new Inputmask("(99) 99999-9999").mask(
      document.getElementById("partner_phone_number")
    );
    if (document.getElementById("partner_main_contact_cpf")) {
      new Inputmask("999.999.999-99").mask(
        document.getElementById("partner_main_contact_cpf")
      );
      new Inputmask("(99) 99999-9999").mask(
        document.getElementById("partner_main_contact_phone_number")
      );
    }

    const documentTypeSelect = document.getElementById("document-type");
    documentTypeSelect.addEventListener("change", (e) => {
      if (e.target.value === "cpf") {
        document.querySelector(".partner_cpf").classList.remove("hide");
        document.querySelector(".partner_cnpj").classList.add("hide");
      } else {
        document.querySelector(".partner_cpf").classList.add("hide");
        document.querySelector(".partner_cnpj").classList.remove("hide");
      }
    });
    document.querySelector(".partner_cpf").classList.add("hide");

    document
      .getElementById("partner_cep")
      .addEventListener("input", async (e) => {
        const cep = e.currentTarget.value.replace(/\D+/g, "");
        if (cep.length === 8) {
          const url = `https://viacep.com.br/ws/${cep}/json/`;

          const response = await axios.get(url);
          if (response.status === 200 && !response.data.erro) {
            document.getElementById("partner_street_name").value =
              response.data.logradouro;
            document.getElementById("partner_neighborhood").value =
              response.data.bairro;
            document.getElementById("partner_city").value =
              response.data.localidade;
            document.getElementById("partner_state").value = response.data.uf;
          } else {
            document.getElementById("partner_street_name").value =
              "CEP Inválido";
            document.getElementById("partner_neighborhood").value =
              "CEP Inválido";
            document.getElementById("partner_city").value = "CEP Inválido";
            document.getElementById("partner_state").value = "CEP Inválido";
          }
        } else {
          document.getElementById("partner_street_name").value = "";
          document.getElementById("partner_neighborhood").value = "";
          document.getElementById("partner_city").value = "";
          document.getElementById("partner_state").value = "";
        }
      });
  }
});
